<div class="row clsTitulo">
    <div class="col-10">
        <h2>Empresa</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-building"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">

        <div class="card mb-3" style="width: auto; height: auto;">
            <div class="row g-0">
                <div class="col-md-4">
                    <!-- <img src="..." class="img-fluid rounded-start" alt="..."> -->
                </div>
                <div class="col-md-12">
                    <div class="card-body">
                        <h1 data-css-12b8ff1="">
                            <div data-css-12johm7="">
                                <span class="highlighted">
                                </span>
                                <div [innerHTML]='data.nombre' style="text-align: left; font-style: italic; color: #2e3e99; font-size: 22px;"></div>
                            </div>
                        </h1>
                    </div>
                    <div [innerHTML]='data.domicilio' style="text-align: justify;"></div>
                </div>
            </div>
        </div>



    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>