import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmacionpagoService } from './Confirmacionpago.service';
@Injectable({
  providedIn: 'root'
})
export class ConfirmacionResolver implements Resolve<boolean> {

  constructor(
    private router: Router,
    private service: ConfirmacionpagoService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    return this.service.GetDatosEmpresa();
  }
}
