import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConfirmacionpagoService {
  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }
  GetDatosEmpresa(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/facturacion/correo_telefono`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  GetCorreo(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/correo/ConfirmacionPago/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  GetCorreoDetalle(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/correo/DetallePago/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  GetCorreoRechazo(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/correo/RechazoPago/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
}
