import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * Metodo para obtener la data de membresia
   * @param id 
   * @returns Observable con membresia
   */
  getListado(referencia: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('referencia', referencia);
    return this.http.get<any>(`${environment.base_url}/membresia/listado`, {params: parametros})
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para obtener la membresia con base en un Identificador o Numero
   * @param id Numero o Identificador de la membresia
   * @returns Observable con membresia
   */
  getMembresia(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/carga/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  
  
  public getCliente(id_membresia: any): Observable<any> | any
  {
  const parametro: HttpParams = new HttpParams()
  .append('id_membresia', id_membresia);
  return this.http.get<any>(`${environment.base_url}/membresia/cliente`, { params: parametro })  
}
  /**
   * Metodo para obtener listados de paises, estado y municipio
   */
   lstMunicipioBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstMunicipioBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  lstEstadoBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstEstadoBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  lstPaisBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstPaisBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  lstClasificaBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstClasificaBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  lstEstatusBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstEstatusBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
}
