<div class="row clsTitulo">
    <div class="col-10">
        <h2>Reservación</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-check-circle"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">


        <div class="tab-content">
            <div class="jumbotron text-center">
                <p class="lead">La reservación fue registrada correctamente con la siguiente información:</p>
                <ul class="list-group">
                    <li class="list-group-item">Nombre: <strong>{{nombre}}</strong></li>
                    <li class="list-group-item">Sección: <strong>{{seccion}}</strong></li>
                    <li class="list-group-item">División: <strong>{{division}}</strong></li>
                    <li class="list-group-item">Fecha registro: <strong>{{fecha}}</strong></li>
                    <li class="list-group-item">Horario: <strong>{{horario}}</strong></li>
                    <li class="list-group-item">Folio: <strong>{{folio}}</strong></li>
                    <li class="list-group-item">En linea: <strong>{{enlinea}}</strong></li>
                    <li class="list-group-item">Instructor: <strong>{{nombre_instructor}}</strong></li>
                    <li class="list-group-item">Responsable: <strong>{{nombre_responsable}}</strong></li>
                </ul>
                <hr>
                <p>
                    Si deseas cancelar o modificar <strong>contactanos</strong>
                </p>
                <p class="lead">
                    <button type="button" class="btn btn-procesar rounded-0 mb-2 mr-2" [routerLink]="['/pages/tablero']">Inicio</button>
                </p>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>