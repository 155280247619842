import { Component, ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { UsuarioService } from '../auth/services/usuario.service';
import { DataManager } from '@syncfusion/ej2-data';
import { MdlUsuario } from '../auth/models/MdlUsuario';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent {
  @ViewChild('sidebarMenuInstance')
  public sidebarMenuInstance?: SidebarComponent;
  public width: string = '220px';
  public mediaQuery: string = '(min-width: 600px)';
  public target: string = '.main-content';
  public dockSize: string = '50px';
  public enableDock: boolean = true;
  public notificacion: any = [];
  public NotificacionPosicion: number = 1;
  public today = new Date();
  public dm: DataManager;
  public menu: { [key: string]: Object }[];
  public usuario: MdlUsuario;

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  constructor(
    private appService: AppService,
    private usuarioService: UsuarioService,
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.usuarioService.logout();
  }
}
