import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmacionpagoService } from '../confirma-pago/Services/Confirmacionpago.service';

@Component({
  selector: 'app-rechazo',
  templateUrl: './rechazo.component.html',
  styleUrls: ['./rechazo.component.scss']
})
export class RechazoComponent implements OnInit {
  public dataFacturacion: any;
  constructor
  (
    private route: ActivatedRoute,
    private Services: ConfirmacionpagoService
  ) 
  {
    this.route.data.subscribe((resp) => {
      this.dataFacturacion = resp.info.respuesta;
    });   
  }

  ngOnInit(): void {
  }

}
