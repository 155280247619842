import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmpresaService } from './Services/Empresa.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  public data: any;
  public info_empresa: any;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private Services: EmpresaService
  ) {
      this.route.data.subscribe((resp) => {
      this.data = resp.info.respuesta;
    });   
   }

  ngOnInit(): void {
  }

}
