import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { ReservacionService } from './reservacion.service';

@Injectable({
  providedIn: 'root'
})
export class ReservacionResolver implements Resolve<boolean> {
  public usuario: MdlUsuario;
  public id_contacto: number = 0;


  constructor(
    private router: Router,
    private reservacionService: ReservacionService
    ) {
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.id_contacto = this.usuario.id;
     }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    return this.reservacionService.getTitutar(this.id_contacto);
  }
}
