import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { UsuarioService } from '../../auth/services/usuario.service';
import * as $ from 'jquery';
import * as jQuery from 'jquery';
import { BeforeOpenCloseMenuEventArgs, MenuAnimationSettingsModel } from '@syncfusion/ej2-navigations';
import { closest } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public menu: { [key: string]: Object }[];
  public NotificacionPosicion: number = 1;
  public notificacion: any = [];

  constructor(
    private usuarioService: UsuarioService,
    private appService: AppService,
    private http: HttpClient
  ) { }
  isCollapsed = true;
  public animation: MenuAnimationSettingsModel = { duration: 800 };

  ngOnInit() {

  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  cargaNotificacion() {
    const base_url = environment.base_url;
    this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
      respuesta => {
        this.notificacion = respuesta;
      }
    );
  }

  onNotificacionChange(posicion: number): void {
    this.NotificacionPosicion = posicion;
    if (this.NotificacionPosicion == 1) {
      this.cargaNotificacion();
    }
  }

  logout(): void {
    this.usuarioService.logout();
  }

  public onBeforeOpen(args: BeforeOpenCloseMenuEventArgs): void {
    // Determinamos el tamaño del menu a mostrar
    (closest(args.element, '.e-menu-wrapper') as HTMLElement).style.height = '450px';
  }

}
