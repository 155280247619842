<div class="row clsTitulo">
    <div class="col-10">
        <h2>Aviso de Privaciad</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-hands-helping"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">

        <div data-css-8mxuy9="">
            <div class="box-body">
                <div [innerHTML]="data" style="text-align: justify;"></div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>