import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmacionreserva',
  templateUrl: './confirmacionreserva.component.html',
  styleUrls: ['./confirmacionreserva.component.scss']
})
export class ConfirmacionreservaComponent implements OnInit,OnDestroy {
  public nombre: string;
  public fecha: string;
  public horario: string;
  public folio: string;
  public division: string;
  public seccion: string;
  public nombre_instructor: string;
  public nombre_responsable: string;
  public enlineabolean: boolean;
  public enlinea: string;

  constructor(
    private router: Router,
    private http: HttpClient,
  )
  {
    let id_reservacion = localStorage.getItem('id_reservacion');
    this.http
    .get<any>(
      `${environment.base_url}/membresia/reservacion/cargareservacion/${id_reservacion}`
    )
    .subscribe((resp) => {
      this.nombre = resp.respuesta.nombre;
      this.fecha = resp.respuesta.fecha;
      this.horario = resp.respuesta.horario;
      this.folio = resp.respuesta.folio;
      this.division = resp.respuesta.division;
      this.seccion = resp.respuesta.seccion;
      this.nombre_instructor = resp.respuesta.nombre_instructor;
      this.enlineabolean = resp.respuesta.enlinea;
      this.nombre_responsable = resp.respuesta.nombre_responsable
      if(this.enlineabolean == false){
        this.enlinea = "No";
      }else{
        this.enlinea = "Si";
      }
      if(this.nombre_instructor == "" || this.nombre_instructor == null){
        this.nombre_instructor = "Sin instructor";
      }else{
        this.nombre_instructor = resp.respuesta.nombre_instructor;
      }
      if(this.nombre_responsable == "" || this.nombre_responsable == null){
        this.nombre_responsable = "Sin responsable";
      }else{
        this.nombre_responsable = resp.respuesta.nombre_responsable;
      }

    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    localStorage.setItem('id_reservacion', '');

  }

}
