import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * 
   * @returns 
   */
  public Empresa(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/sistema/empresa/datos`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }


}
